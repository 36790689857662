<template>
  <div>
    <footer class="footer">
      <a href="#" class="go-top"><i class="fa fa-angle-up"></i></a>
      <b-container>
        <div class="columns is-multiline is-">
          <div class="column">
            <img
              class="img-responsive"
              src="@/assets/estia_logo_footer.png"
              alt="EstiaLabs"
              style="height: 124px"
            />
            <div class="copyright">
              &copy; Copyright {{ new Date().getFullYear() }} |
              <b-link to="/privacy-policy" rel="noopener">Privacy</b-link> |
              <a href="https://www.estiatuition.com/contact-us" target="_blank">Contact Us</a>
            </div>
          </div>
          <div class="column">
            <p class="footer-disclaimer">
              Give your child the UK's best home-based tuition!
            </p>
          </div>
        </div>
      </b-container>
    </footer>
  </div>
</template>
  
<script>
export default {
  name: "TheFooter",
};
</script>